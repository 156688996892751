<template>
	<v-hover v-slot="{ hover }">
		<!-- min-width="280" -->
		<v-card
			width="100%"
			:elevation="hover ? 5 : 0"
			:class="{ 'on-hover': hover }"
			class="mb-3 transition-fast-in-fast-out"
			active-class="card-active"
			outlined
			exact
			exact-active-class="card-active"
			link
			:id="item._id"
			:to="{
				path: `/tenants/${item.id}`,
				query: $route.query,
			}"
		>
			<v-card-title
				class="border-bottom subtitle-2 d-flex justify-space-between align-center pa-3"
			>
				<!-- <span>{{ item.owners[0].user | fullname }}</span> -->
				<span>{{ item.house.apartment_address }}</span>
			</v-card-title>

			<v-card-text class="border-bottom pa-3 d-flex flex-column">
				<!-- <span class="caption font-weight-light">
					{{ item.owners[0].affilation.name || "Не указано" }}
				</span>
				<span class="caption font-weight-light">
					{{ item.owners[0].user.phone  || "Не указано" }}
				</span> -->
				<span>{{ item.apartment_number ? `кв. ${item.apartment_number}` : ''}}</span>
				<span>{{ item.porch_number ? `Подъезд ${item.porch_number}` : ''}}</span>
				<span>{{ item.floor_number ? `Этаж ${item.floor_number}` : ''}}</span>
			</v-card-text>
		</v-card>
	</v-hover>
</template>

<script>
export default {
	name: "TenantsItemListCard",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.v-card {
	width: 100% !important;
	&.card-active {
		border: thin solid #2eb85c !important;
		background: rgba(46, 184, 92, 0.09);
	}
}
</style>
