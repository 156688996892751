<template>
	<div class="tenants-item-list d-flex flex-column">
		<div class="border d-flex justify-space-between align-center py-5 px-3">
			<v-btn
				class="pa-0"
				link
				depressed
				plain
				@click="$router.push({ path: '/tenants', query: $route.query })"
			>
				<v-icon color="grey darken-1" small>mdi-chevron-left</v-icon>
				<span class="caption">Список</span>
			</v-btn>
		</div>

		<!-- Спиннер для загрузки новых записей  -->
		<div
			v-if="loadingMore || loading"
			class="d-flex justify-center"
			:class="{
				'h-100 border-right flex-sm-grow-1 overflow-y-auto': loading,
			}"
		>
			<v-progress-linear
				absolute
				indeterminate
				color="green"
			></v-progress-linear>
		</div>

		<div class="d-flex px-3 pt-3 border-right">
			<!-- Filter -->
			<v-text-field
				v-model="phone"
				v-mask="'+7-###-###-##-##'"
				class="border-all"
				solo
				dense
				hide-details
				flat
				clearable
				append-icon="mdi-magnify"
				placeholder="+7-"
			></v-text-field>
		</div>
		<!-- Записи -->
		<div
			class="list border-right d-flex flex-column align-center pa-3 flex-sm-grow-1 overflow-y-auto"
			@scroll="onScroll"
		>
			<TenantsItemListCard
				v-for="item of items"
				:key="item.id"
				:item="item"
				:id="item.id"
				@change-page="changePage(item)"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import TenantsItemListCard from "@/components/TenantsItemListCard";

export default {
	name: "TenantsItemList",
	components: {
		TenantsItemListCard,
	},
	data() {
		return {
			items: [],
			lastPage: null,
			loading: false,
			loadingMore: false,
			scrolledPages: [],
			phone: null,
			timer: null,
			page: +this.$route.query.page || 1,
		};
	},

	watch: {
		phone(val) {
			if (val === "+7-") this.phone = null;
			clearTimeout(this.timer);
			this.timer = setTimeout(
				() => this.loadData(undefined, undefined, true),
				500
			);
		},
	},
	computed: {
		...mapGetters({
			kskId: "auth/GET_KSK_ID",
			houses: "ksk/GET_HOUSES",
		}),

	},
	methods: {
		changePage(item) {
			this.$router.replace({ query: { page: item.page } });
		},

		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			const firstPage = this.items[0].page;
			const lastPage = this.items[this.items.length - 1].page;

			if (
				scrollTop + clientHeight >= scrollHeight &&
				lastPage <= this.lastPage
			) {
				this.loadData();
			} else if (scrollTop === 0 && firstPage - 1 >= 1) {
				this.loadData(false);
			}
		},

		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},

		resetFilters() {
			this.page = 1;
			this.scrolledPages = [];
			this.$router.replace({ query: { page: 1 } });
			this.loadData(undefined, true);
		},

		// https://stackoverflow.com/questions/59603315/how-to-detect-when-a-user-scrolls-to-the-bottom-of-a-div-vue

		async loadData(toDown = true, update = false, filter = false) {
			try {
				let newPage;

				if (filter) newPage = 1;
				else if (update) newPage = this.page;
				else if (toDown) newPage = this.items[this.items.length - 1].page + 1;
				else newPage = this.items[0].page - 1;

				if (!filter && this.scrolledPages.includes(newPage)) {
					return;
				}

				if (update) {
					this.loading = true;
				} else {
					this.loadingMore = true;
				}

				const params = {
					...this.$route.query,
					phone: this.phone,
					page: newPage,
				};
				const kskHouseIds = this.houses.map(house => house.id);
				if(!kskHouseIds.length) {
					params.ksk_ids = [this.kskId]
				} else {
					params.houses_ids = kskHouseIds;
				}
				const res = await this.$api.ksk.load_tenants(params);

				res.records = res.records.map(e => ({ ...e, page: newPage }));
				this.lastPage = res.pages;

				if (update || filter) {
					this.items = [...res.records];
				} else if (toDown) {
					this.scrolledPages.push(newPage);
					this.items = [...this.items, ...res.records];
				} else {
					this.scrolledPages.unshift(newPage);
					this.items = [...res.records, ...this.items];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = this.loadingMore = false;
			}
		},
	},

	async created() {
		await this.loadData(undefined, true);

		if (this.items.length < 10) {
			if (this.page - 1 >= 1) {
				this.page--;
				await this.loadData(false);
			}
		}
		// const { _id } = this.items.find(e => e._id === this.$route.params.id);
		// setTimeout(() => {
		// 	this.scrollIntoView(_id);
		// }, 100);
	},
};
</script>

<style lang="scss" scoped>
.tenants-item-list {
	height: 100%;
	position: relative;
}
.h-100 {
	height: 100vh;
}
.list {
	flex: 1;
	max-height: 700px;
}
</style>
